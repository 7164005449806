import styled from 'styled-components';

import { DraggableModal } from 'components/draggable-modal';
import { H3 } from 'components/typography';

import { EmploymentInfo } from '../employment-info';
import { SectorLimits } from '../sector-limits';
import { PositionLimits } from '../position-limits';
import { WorkLimitsNotification } from '../work-limits-notification';

import { useWorkLimitsModal } from './work-limits-modal.state';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const Styles = {
  Root: styled(DraggableModal)`
    & .modal-window {
      position: relative;
      max-height: calc(100vh - 80px);
      display: flex;
      flex-direction: column;
      padding: 24px;
    }
  `,

  Title: styled(H3)`
    flex: 0 0 auto;
    padding-bottom: 16px;
  `,

  Scrollable: styled.div`
    margin: 0 -12px;
    padding: 0 12px;
    flex: 1 1 auto;
    overflow-y: auto;
    display: grid;
    justify-content: start;
    row-gap: 40px;
    cursor: default;
  `,

  EmploymentInfoWrapper: styled.div`
    display: grid;
    justify-items: start;
  `,
};

export const WorkLimitsModal = (props: IProps) => {
  const { isOpen, onClose } = props;

  const {
    isShowNotification,
    scrollableRef,
    onNonDraggableMouseDown,
    scrollToBottom,
    hideNotification,
    showNotification,
  } = useWorkLimitsModal();

  return (
    <Styles.Root isOpen={isOpen} onCloseModal={onClose}>
      <WorkLimitsNotification isShow={isShowNotification} />

      <Styles.Title typography="RalewayBold24" textAlign="left">
        Job Limits
      </Styles.Title>

      <Styles.Scrollable
        ref={scrollableRef}
        onMouseDown={onNonDraggableMouseDown}
      >
        <Styles.EmploymentInfoWrapper>
          <EmploymentInfo />
        </Styles.EmploymentInfoWrapper>

        <SectorLimits
          hideNotification={hideNotification}
          showNotification={showNotification}
        />

        <PositionLimits
          scrollToBottom={scrollToBottom}
          hideNotification={hideNotification}
          showNotification={showNotification}
        />
      </Styles.Scrollable>
    </Styles.Root>
  );
};
