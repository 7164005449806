import styled, { css } from 'styled-components';

import { Grid, Input } from 'components/UI';
import { Checkbox } from 'components/checkbox';
import { checkInt } from 'services/utils';
import { COLORS, typographyCss } from 'theme';

interface IProps {
  limit: number;
  isChanged: boolean;
  isSelected: boolean;
  onChange: (limit: number) => void;
  onSelect: () => void;
}

const Styles = {
  Input: styled(Input)<{ isChanged: boolean }>`
    width: 154px;
    min-width: 100px;
    border-radius: 7px;
    text-align: center;
    padding: 3px 12px;

    ${({ isChanged }) =>
      isChanged
        ? css`
            ${typographyCss.QuicksandBold18};
            color: ${COLORS.redSeconday};
          `
        : css`
            ${typographyCss.QuicksandRegular18};
            color: ${COLORS.black};
          `}
  `,
};

export const SectorLimitLimit = (props: IProps) => {
  const { limit, isChanged, isSelected, onChange, onSelect } = props;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const strValue = event.target.value;

    if (strValue.length === 0) {
      return onChange(0);
    }

    if (!checkInt(strValue)) {
      return;
    }

    onChange(+strValue);
  };

  return (
    <Grid columns={2} alignItems="center" columnGap="12px">
      <Checkbox isChecked={isSelected} onClick={onSelect} />

      <Styles.Input
        isChanged={isChanged}
        value={limit}
        onChange={handleChange}
      />
    </Grid>
  );
};
