import styled from 'styled-components';

import { Button } from 'components/button';

interface IProps {
  isLoading: boolean;
  isCanSave: boolean;
  onSave: () => void;
}

const Styles = {
  Root: styled.div`
    display: grid;
    justify-content: end;
    grid-template-columns: auto;
    column-gap: 16px;
  `,
};

export const SectorLimitsButton = (props: IProps) => {
  const { isLoading, isCanSave, onSave } = props;

  const buttonLabel = isLoading ? 'Saving...' : 'Save';

  return (
    <Styles.Root>
      <Button isDisabled={isLoading || !isCanSave} onClick={onSave}>
        {buttonLabel}
      </Button>
    </Styles.Root>
  );
};
