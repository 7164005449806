import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { keyofObject, valuesOfObject } from 'services/object';
import {
  TSectorEmployees,
  TSectorSettings,
  TWorkLimitParam,
} from 'typings/sectors-settings';
import { workLimitsDB } from 'services/realtime-firebase.api';

import { useActiveGameContext } from '../../../../active-game.context';

import {
  getChangedEntries,
  getSectorSettings,
  pushChangedEntries,
  unchangeSectorSettings,
} from './sector-limits-editor.utils';
import { SECTOR_LIMITS_EDITOR_CONSTANTS } from './sector-limits-editor.constants';

interface IProps {
  hideNotification: () => void;
  showNotification: () => void;
}

interface IParam {
  selected: string[];
  isApplied: boolean;
}

interface IState {
  sectorSettings: TSectorSettings;
  limit: IParam;
  salary: IParam;
}

const INIT_STATE: IState = {
  sectorSettings: {},

  limit: {
    selected: [],
    isApplied: false,
  },

  salary: {
    selected: [],
    isApplied: false,
  },
};

export const useSectorLimitsEditor = (props: IProps) => {
  const { hideNotification, showNotification } = props;

  const { game } = useActiveGameContext();

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [state, setState] = useState<IState>(INIT_STATE);

  const initSectorSettingsRef = useRef<TSectorSettings | null>(null);
  const sectorEmployeesRef = useRef<TSectorEmployees | null>(null);

  const checkAllParamSelected = useCallback(
    (param: TWorkLimitParam) =>
      state[param].selected.length > 0 &&
      keyofObject(state.sectorSettings)?.every((sector) =>
        state[param].selected.includes(sector)
      ),

    // Check only when selected changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.limit.selected, state.salary.selected]
  );

  const isAllLimitsSelected = useMemo(
    () => checkAllParamSelected('limit'),
    [checkAllParamSelected]
  );

  const isAllSalariesSelected = useMemo(
    () => checkAllParamSelected('salary'),
    [checkAllParamSelected]
  );

  const settingsValues = valuesOfObject(state.sectorSettings);

  const isNeedLimitApply = settingsValues.some(({ limit }) => limit.isChanged);

  const isNeedSalaryApply = settingsValues.some(
    ({ salary }) => salary.isChanged
  );

  const isLimitApplyDisabled = !isNeedLimitApply || state.limit.isApplied;

  const isSalaryApplyDisabled = !isNeedSalaryApply || state.salary.isApplied;

  const isCantSave =
    isSaving ||
    (!isNeedLimitApply && !isNeedSalaryApply) ||
    (isNeedLimitApply && !state.limit.isApplied) ||
    (isNeedSalaryApply && !state.salary.isApplied);

  const onSelectParamCb = useCallback(
    (param: TWorkLimitParam) => (sector: string) => {
      setState((prev) => {
        const selected = prev[param].selected;

        if (selected.includes(sector)) {
          return {
            ...prev,
            [param]: {
              ...prev[param],
              selected: selected.filter((prevSector) => prevSector !== sector),
            },
          };
        }

        return {
          ...prev,
          [param]: {
            ...prev[param],
            selected: [...prev[param].selected, sector],
          },
        };
      });
    },
    []
  );

  const onSelectLimit = useMemo(
    () => onSelectParamCb('limit'),
    [onSelectParamCb]
  );

  const onSelectSalary = useMemo(
    () => onSelectParamCb('salary'),
    [onSelectParamCb]
  );

  const onToggleAll = useCallback(
    (param: TWorkLimitParam) => {
      setState((prev) => ({
        ...prev,

        [param]: {
          ...prev[param],
          selected: checkAllParamSelected(param)
            ? INIT_STATE[param].selected
            : keyofObject(prev.sectorSettings),
        },
      }));
    },
    [checkAllParamSelected]
  );

  const changeParam = useCallback(
    (param: TWorkLimitParam, sector: string, value: number) => {
      setState((prev) => {
        const sectors = prev[param].selected.includes(sector)
          ? prev[param].selected
          : [sector];

        const updated = sectors.reduce(
          (acc, sec) => ({
            ...acc,
            [sec]: {
              ...prev.sectorSettings[sec],
              [param]: {
                value,
                // denormalized for optimization
                isChanged:
                  initSectorSettingsRef.current?.[sec][param].value !== value,
              },
            },
          }),
          {}
        );

        return {
          ...prev,

          sectorSettings: {
            ...prev.sectorSettings,
            ...updated,
          },

          [param]: {
            ...prev[param],
            isApplied: false,
          },
        };
      });
    },
    []
  );

  const onChangeLimit = useCallback(
    (sector: string, value: number) => {
      if (value > SECTOR_LIMITS_EDITOR_CONSTANTS.LIMIT_MAX) {
        return;
      }

      changeParam('limit', sector, value);
    },
    [changeParam]
  );

  const onChangeSalary = useCallback(
    (sector: string, value: number) => {
      if (value < SECTOR_LIMITS_EDITOR_CONSTANTS.SALARY_MIN) {
        return;
      }

      changeParam('salary', sector, value);
    },
    [changeParam]
  );

  const onApplyParam = useCallback((param: TWorkLimitParam) => {
    setState((prev) => ({
      ...prev,
      [param]: {
        ...prev[param],
        isApplied: true,
      },
    }));
  }, []);

  const onSave = async () => {
    if (isCantSave) {
      return;
    }

    const changedEntries = getChangedEntries(state.sectorSettings);

    if (!changedEntries.length) {
      return;
    }

    setIsSaving(true);

    hideNotification();

    try {
      await pushChangedEntries(game.config.gameCode, changedEntries);

      const sectorSettings = unchangeSectorSettings(state.sectorSettings || {});

      initSectorSettingsRef.current = structuredClone(sectorSettings);

      setState((prev) => ({
        ...prev,
        sectorSettings,
      }));

      showNotification();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const request = async () => {
      const sectors = await workLimitsDB.getSectors(game.config.gameCode);

      const { sectorSettings: initSettings, sectorEmployees } =
        getSectorSettings(sectors);

      setState((prev) => ({
        ...prev,
        sectorSettings: initSettings,
      }));

      setIsLoading(false);

      initSectorSettingsRef.current = structuredClone(initSettings);
      sectorEmployeesRef.current = sectorEmployees;
    };

    request();
  }, [game.config.gameCode]);

  return {
    ...state,
    isLoading,
    isSaving,
    sectorEmployees: sectorEmployeesRef.current || {},
    isAllLimitsSelected,
    isAllSalariesSelected,
    isLimitApplyDisabled,
    isSalaryApplyDisabled,
    isCantSave,

    onChangeLimit,
    onChangeSalary,
    onSelectLimit,
    onSelectSalary,
    onToggleAll,
    onApplyParam,
    onSave,
  };
};
