import { entriesObject } from '@avid/common';

import { callGameAdminFunction, GAME_ADMIN_ACTIONS } from 'services/api';
import { keyofObject } from 'services/object';
import { workLimitsDB } from 'services/realtime-firebase.api';
import { getGameIdentity } from 'services/utils';
import { TDBSectors } from 'typings/games';
import {
  ISectorsSettings,
  TSectorEmployees,
  TSectorSettings,
} from 'typings/sectors-settings';

export const getSectorSettings = (dbSectors: TDBSectors) => {
  const entries = entriesObject(dbSectors);

  const sectorSettings: TSectorSettings = {};

  const sectorEmployees: TSectorEmployees = {};

  entries.forEach(([sector, data]) => {
    sectorSettings[sector] = {
      // denormalized for optimization
      limit: { value: +data.limit, isChanged: false },
      salary: {
        value: +((data.salary || 1) * 100).toFixed(),
        isChanged: false,
      },
    };

    sectorEmployees[sector] = data.players
      ? keyofObject(data.players).length
      : 0;
  });

  return { sectorSettings, sectorEmployees };
};

export const getChangedEntries = (sectorSettings: TSectorSettings) =>
  entriesObject(sectorSettings)?.filter(
    ([, item]) => item.limit.isChanged || item.salary.isChanged
  );

const filterSalariesEntries = (entries: [string, ISectorsSettings][]) =>
  entries.filter(([, settings]) => settings.salary.isChanged);

const updateSalaries = (
  gameCode: string,
  entries: [string, ISectorsSettings][]
) => {
  if (!entries.length) {
    return;
  }

  const mapped: [string, number][] = entries.map(([sector, settings]) => [
    sector,
    +(settings.salary.value * 0.01).toFixed(1),
  ]);

  return callGameAdminFunction(
    getGameIdentity(gameCode),
    GAME_ADMIN_ACTIONS.UPDATE_SALARIES,
    { sectorSalaries: mapped }
  );
};

const updateSalariesFromEntries = (
  gameCode: string,
  entries: [string, ISectorsSettings][]
) => {
  const changed = filterSalariesEntries(entries);

  if (!changed.length) {
    return;
  }

  return updateSalaries(gameCode, changed);
};

const firePlayers = async (
  gameCode: string,
  entries: [string, ISectorsSettings][]
) => {
  const limits = entries.reduce(
    (acc, [sector, settings]) => {
      acc[sector] = settings.limit.value;

      return acc;
    },
    {} as Record<string, number>
  );

  try {
    await callGameAdminFunction(
      getGameIdentity(gameCode),
      GAME_ADMIN_ACTIONS.FIRE_PLAYERS,
      { limits }
    );
  } catch (error) {
    console.error(error);
  }
};

export const unchangeSectorSettings = (sectorItems: TSectorSettings) =>
  entriesObject(sectorItems).reduce((acc, [sector, settings]) => {
    acc[sector] = {
      ...settings,
      salary: { ...settings.salary, isChanged: false },
      limit: { ...settings.limit, isChanged: false },
    };

    return acc;
  }, {} as TSectorSettings);

export const pushChangedEntries = async (
  gameCode: string,
  entries: [string, ISectorsSettings][]
) => {
  updateSalariesFromEntries(gameCode, entries);

  await Promise.all(
    entries.map(([sector, item]) =>
      workLimitsDB.updateSectorParams({
        gameCode,
        sector,
        limit: item.limit.value,
        salary: +(item.salary.value * 0.01).toFixed(1),
      })
    )
  );

  await firePlayers(gameCode, entries);
};
