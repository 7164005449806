import styled from 'styled-components';

import { Theme } from 'theme';

export const PlayersListStyles = {
  Block: styled.div<{ marginTop?: string }>`
    margin-top: ${(props) => props.marginTop};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  `,
  ChildrenWrapper: styled.div`
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    margin: 0 40px 20px 40px;
    align-self: center;
  `,
  Title: styled(Theme.Title)`
    margin-top: 32px;
    margin-bottom: 32px;
    flex: 1;
  `,
};
