"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GamesFirestore = void 0;
var GamesFirestore = /** @class */ (function () {
    function GamesFirestore(rawFirestore) {
        this.rawFirestore = rawFirestore;
        // construct
    }
    // games
    GamesFirestore.prototype.getGame = function (gameCode) {
        return this.rawFirestore.getGame(gameCode);
    };
    GamesFirestore.prototype.onGame = function (gameCode, onNext) {
        return this.rawFirestore.onGame(gameCode, onNext);
    };
    // games - [code]
    // games - [code] - users
    GamesFirestore.prototype.getPlayers = function (gameCode) {
        return this.rawFirestore.getPlayers(gameCode);
    };
    GamesFirestore.prototype.getPlayer = function (gameCode, playerEmail) {
        return this.rawFirestore.getPlayer(gameCode, playerEmail);
    };
    GamesFirestore.prototype.mergePlayer = function (gameCode, playerEmail, data) {
        return this.rawFirestore.mergePlayer(gameCode, playerEmail, data);
    };
    // games - [code] - mods
    GamesFirestore.prototype.onLimitedEducationGameModRound = function (params) {
        return this.rawFirestore.onLimitedEducationGameModRound(params);
    };
    // games - [code] - reflection-answers
    GamesFirestore.prototype.getReflectionAnswers = function (gameCode, email) {
        return this.rawFirestore.getReflectionAnswers(gameCode, email);
    };
    return GamesFirestore;
}());
exports.GamesFirestore = GamesFirestore;
