import { memo, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Checkbox } from 'components/checkbox';
import { Icon } from 'components/icons';
import { Span } from 'components/typography';
import { COLORS, typographyCss } from 'theme';
import { TWorkLimitParam } from 'typings/sectors-settings';

interface IProps {
  isAllLimitsSelected: boolean;
  isAllSalariesSelected: boolean;
  onToggleAllItems: (selection: TWorkLimitParam, isSelected: boolean) => void;
}

const itemCss = css`
  border-radius: 7px 7px 0px 0px;
  background-color: ${COLORS.white};
  box-shadow: 2px 0px 16px 0px rgba(45, 45, 55, 0.1);
  padding: 8px 16px;
  display: grid;
  vertical-align: bottom;
`;

const Styles = {
  Root: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3px;
    border-bottom: 3px solid ${COLORS.darkPurple};
    ${typographyCss.QuicksandBold16};
  `,

  Item: styled.div`
    ${itemCss};
    grid-template-columns: 1fr auto;
  `,

  CheckboxItem: styled.div`
    ${itemCss};
    grid-template-columns: auto 1fr;
    align-items: center;
  `,

  DropArrow: styled(Icon)`
    justify-self: end;
  `,
};

export const SectorLimitsHead = memo((props: IProps) => {
  const { isAllLimitsSelected, isAllSalariesSelected, onToggleAllItems } =
    props;

  const onToggleLimits = useCallback(
    () => onToggleAllItems('limit', isAllLimitsSelected),
    [isAllLimitsSelected, onToggleAllItems]
  );

  const onToggleSalaries = useCallback(
    () => onToggleAllItems('salary', isAllSalariesSelected),
    [isAllSalariesSelected, onToggleAllItems]
  );

  return (
    <Styles.Root>
      <Styles.Item>
        <Span typography="QuicksandBold16" textAlign="left">
          Sector
        </Span>
      </Styles.Item>

      <Styles.Item>
        <Span typography="QuicksandBold16"> No. of Employees</Span>

        <Styles.DropArrow type="arrowDownGrey" />
      </Styles.Item>

      <Styles.CheckboxItem>
        <Checkbox isChecked={isAllLimitsSelected} onClick={onToggleLimits} />

        <Span typography="QuicksandBold16">Vacancies</Span>
      </Styles.CheckboxItem>

      <Styles.CheckboxItem>
        <Checkbox
          isChecked={isAllSalariesSelected}
          onClick={onToggleSalaries}
        />

        <Span typography="QuicksandBold16">Salary %</Span>
      </Styles.CheckboxItem>
    </Styles.Root>
  );
});
