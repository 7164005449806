import { realtimeDatabase } from './firebase';
import { TDBSectors, IJobSectorData } from 'typings/games';

import { valuesOfObject } from './object';

interface ISetSectorParams {
  gameCode: string;
  sector: string;
  salary: number;
  limit: number;
}

export const getEmployees = async (gameCode: string) => {
  const snap = await realtimeDatabase.ref(`games/${gameCode}/sectors`).get();

  const sectors = snap.val();
  const sectorValues = valuesOfObject(sectors);

  const result: number =
    sectorValues &&
    sectorValues.reduce(
      (totalEmployees: number, currentSector: IJobSectorData) => {
        return (totalEmployees = currentSector?.players
          ? +(totalEmployees + Object.values(currentSector.players).length)
          : totalEmployees);
      },
      0
    );

  return result;
};

const refs = {
  sectors(gameCode: string) {
    return realtimeDatabase.ref(`games/${gameCode}/sectors`);
  },

  sector(gameCode: string, sector: string) {
    return this.sectors(gameCode).child(sector);
  },
};

export const workLimitsDB = {
  async getSectors(gameCode: string) {
    const ref = refs.sectors(gameCode);

    const sectorsSnapshot = await ref.get();

    const sectorsValue = sectorsSnapshot.val() as TDBSectors;

    return sectorsValue;
  },

  updateSectorParams(params: ISetSectorParams) {
    const { gameCode, limit, salary, sector } = params;

    const ref = refs.sector(gameCode, sector);

    return ref.update({ limit, salary });
  },
};
