import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { PositionLimitsEditor } from 'components/position-limits-editor';
import { Grid } from 'components/UI';
import { Paragraph } from 'components/typography';

import { useActiveGameContext } from '../../../active-game.context';

interface IProps {
  scrollToBottom: () => void;
  hideNotification: () => void;
  showNotification: () => void;
}

const Styles = {
  TitleWrapper: styled.div<{ $isOpen: boolean }>`
    display: grid;
    grid-auto-flow: column;
    column-gap: 12px;
    justify-content: start;
    align-items: center;
    cursor: ${({ $isOpen }) => ($isOpen ? 'default' : 'pointer')};
  `,

  Triangle: styled.div<{ $isOpen: boolean }>`
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid #000;

    transition: transform 0.3s ease-in-out;

    transform: rotate(${({ $isOpen }) => ($isOpen ? '0deg' : '180deg')});
  `,
};

export const PositionLimits = (props: IProps) => {
  const { scrollToBottom, hideNotification, showNotification } = props;

  const { game } = useActiveGameContext();

  const [isShow, setIsShow] = useState(false);

  const onShow = () => {
    if (!isShow) {
      setIsShow(true);
    }
  };

  const onMount = useCallback(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const RenderPositionLimitsEditor = isShow && game?.config.version && (
    <PositionLimitsEditor
      gameCode={game?.config.gameCode}
      version={game?.config.version}
      scrollBottom={onMount}
      hideNotification={hideNotification}
      showNotification={showNotification}
    />
  );

  return (
    <Grid rowGap="12px">
      <Styles.TitleWrapper $isOpen={isShow} onClick={onShow}>
        <Paragraph typography="RalewaySemiBold20" textAlign="left">
          Advanced Job Limits
        </Paragraph>

        <Styles.Triangle $isOpen={isShow} />
      </Styles.TitleWrapper>

      {RenderPositionLimitsEditor}
    </Grid>
  );
};
