import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TGameVersion } from '@avid/common';
import deepEqual from 'deep-equal';

import { gamesAPI, versionsAPI } from 'services/api';
import { useUpdateState } from 'services/hooks';
import { careerTreeToSortedUniqueJobsMap } from 'services/utils';

interface IUsePositionLimitsEditorProps {
  gameCode: string;
  scrollBottom: () => void;
  hideNotification: () => void;
  showNotification: () => void;
}

interface IUseSectorJobsMapLoaderProps {
  version?: TGameVersion;
}

interface IPositionsLimitsLoaderState {
  isLoaded: boolean;
  sectorJobsMap?: Record<string, string[]>;
}

interface IPositionsLimitsEditorState {
  isLoading: boolean;
  keys: IPositionLimitKey[];
  limits: number[];
}

const INIT_POSITIONS_LIMIT_EDITOR_STATE: IPositionsLimitsEditorState = {
  isLoading: true,
  keys: [],
  limits: [],
};

const INIT_POSITIONS_LIMIT_LOADER_STATE: IPositionsLimitsLoaderState = {
  isLoaded: false,
};

export const usePositionLimitsEditor = (
  props: IUsePositionLimitsEditorProps
) => {
  const { gameCode, scrollBottom, hideNotification, showNotification } = props;

  const [state, setState] = useState(INIT_POSITIONS_LIMIT_EDITOR_STATE);
  const [isSaving, setIsSaving] = useState(false);

  const initItemsRef = useRef<IPositionLimitItem[] | null>(null);

  const handleScrollBottom = useCallback(() => {
    setTimeout(() => {
      scrollBottom();
    }, 320);
  }, [scrollBottom]);

  const onAddLimit = useCallback(() => {
    setState((prev) => {
      const isItemExist =
        prev.keys.findIndex((key) => key.sector === '' && key.job === '') !==
        -1;

      if (isItemExist) {
        return prev;
      }

      return {
        ...prev,
        keys: [...prev.keys, { sector: '', job: '' }],
        limits: [...prev.limits, 0],
      };
    });

    handleScrollBottom();
  }, [handleScrollBottom]);

  const removeLimitCb = useCallback(
    (index: number) => () => {
      setState((prev) => ({
        ...prev,
        keys: prev.keys.filter((_, i) => i !== index),
        limits: prev.limits.filter((_, i) => i !== index),
      }));
    },
    []
  );

  const setSectorCb = useCallback(
    (index: number): ChangeEventHandler<HTMLSelectElement> =>
      (ev) => {
        setState((prev) => {
          const sector = ev.target.value;
          const selectedItem = prev.keys[index];

          const isItemExist =
            prev.keys.findIndex(
              (item, findIndex) =>
                item.sector === sector &&
                item.job === selectedItem.job &&
                index !== findIndex
            ) !== -1;

          if (isItemExist) {
            return prev;
          }

          return {
            ...prev,
            keys: prev.keys.map((item, i) =>
              i === index ? { ...item, sector } : item
            ),
          };
        });
      },
    []
  );

  const setJobCb = useCallback(
    (index: number): ChangeEventHandler<HTMLSelectElement> =>
      (ev) => {
        setState((prev) => {
          const job = ev.target.value;
          const selectedItem = prev.keys[index];

          const isItemExist =
            prev.keys.findIndex(
              (item, findIndex) =>
                item.sector === selectedItem.sector &&
                item.job === job &&
                index !== findIndex
            ) !== -1;

          if (isItemExist) {
            return prev;
          }

          return {
            ...prev,
            keys: prev.keys.map((item, i) =>
              i === index ? { ...item, job } : item
            ),
          };
        });
      },
    []
  );

  const setLimitCb = useCallback(
    (index: number) => (limit: number) =>
      setState((prev) => ({
        ...prev,
        limits: prev.limits.map((item, i) => (i === index ? limit : item)),
      })),
    []
  );

  const onSave = useCallback(async () => {
    if (isSaving) {
      return;
    }

    const items = state.keys.map((key, index) => ({
      ...key,
      limit: state.limits[index],
    }));

    if (deepEqual(initItemsRef.current, items)) {
      return;
    }

    setIsSaving(true);
    hideNotification();

    try {
      await gamesAPI.setPositionLimits(gameCode, items);

      initItemsRef.current = items;
      showNotification();
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  }, [
    gameCode,
    isSaving,
    state.keys,
    state.limits,
    hideNotification,
    showNotification,
  ]);

  useEffect(() => {
    const request = async () => {
      const positionLimits = await gamesAPI.getPositionLimits(gameCode);

      if (!positionLimits) {
        return;
      }

      initItemsRef.current = positionLimits.items;

      setState({
        isLoading: false,

        keys: positionLimits.items.map((item) => ({
          sector: item.sector,
          job: item.job,
        })),

        limits: positionLimits.items.map((item) => item.limit),
      });

      handleScrollBottom();
    };

    request();
  }, [gameCode, handleScrollBottom]);

  return {
    ...state,
    isSaving,
    onAddLimit,
    removeLimitCb,
    setSectorCb,
    setJobCb,
    setLimitCb,
    onSave,
  };
};

export const usePositionsLoader = (props: IUseSectorJobsMapLoaderProps) => {
  const { version } = props;

  const { state, updateState } = useUpdateState(
    INIT_POSITIONS_LIMIT_LOADER_STATE
  );

  useEffect(() => {
    if (!version) {
      updateState({ isLoaded: true });
      return;
    }

    const request = async () => {
      try {
        const sectorsRes = await versionsAPI.getSectors(version);

        if (!sectorsRes) {
          updateState({ isLoaded: true });
          return;
        }

        const sectorJobsMap: Record<string, string[]> = {};

        await Promise.all(
          sectorsRes.sectors.map(async (sector) => {
            const data = await versionsAPI.getCareerTree(version, sector.value);

            if (!data) {
              return;
            }

            const uniqueJobs = careerTreeToSortedUniqueJobsMap(data.careerTree);

            sectorJobsMap[sector.value] = uniqueJobs;
          })
        );

        updateState({ isLoaded: true, sectorJobsMap });
      } catch (err) {
        console.error(err);
        updateState({ isLoaded: true });
      }
    };

    request();
  }, [updateState, version]);

  return { ...state };
};
