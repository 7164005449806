import { IGame } from '@avid/common';

import { db } from 'services/firebase';
import { IStatisticsData } from 'typings/games';

interface IPositionLimitsResponse {
  items: IPositionLimitItem[];
}

class GamesAPI {
  async getGame(gameCode: string) {
    const gameDoc = await db.collection('games').doc(gameCode).get();

    return gameDoc.data() as IGame | undefined;
  }

  getPlayersSnapshot(gameCode: string) {
    return db.collection('games').doc(gameCode).collection('users').get();
  }

  async getRoundSnapshot(gameCode: string, roundNumber: number) {
    const doc = await db
      .collection('games')
      .doc(gameCode)
      .collection('snapshots')
      .doc(roundNumber.toString())
      .get();

    return doc.data() as IStatisticsData | undefined;
  }

  async getPositionLimits(gameCode: string) {
    const doc = await db
      .collection('games')
      .doc(gameCode)
      .collection('job-limits')
      .doc('position-limits')
      .get();

    return doc.data() as IPositionLimitsResponse | undefined;
  }

  setPositionLimits(gameCode: string, positionLimits: IPositionLimitItem[]) {
    return db
      .collection('games')
      .doc(gameCode)
      .collection('job-limits')
      .doc('position-limits')
      .set({ items: positionLimits });
  }
}

export const gamesAPI = new GamesAPI();
