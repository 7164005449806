import { CSVLink } from 'react-csv';

import { Button } from 'components/button';

import { useReflectionAnswers } from './reflection-answers.state';

export const HEADERS = [
  { label: 'Player Name', key: 'playerName' },
  { label: 'Player Email', key: 'playerEmail' },
  { label: 'Character Name', key: 'characterName' },
  { label: 'Round No', key: 'round' },
  { label: 'Question Text', key: 'question' },
  { label: 'Answer Text', key: 'answer' },
  { label: 'Answer Timing', key: 'updatedAt' },
];

const FILE_NAME = 'reflection-answers.csv';

export const ReflectionAnswers = () => {
  const { isLoading, linkRef, answers, onClick } = useReflectionAnswers();

  const label = isLoading ? 'Loading...' : 'Download User Answers';

  return (
    <div>
      <Button isLightTheme isDisabled={isLoading} onClick={onClick}>
        {label}
      </Button>

      <CSVLink
        filename={FILE_NAME}
        ref={linkRef}
        headers={HEADERS}
        data={answers || []}
      />
    </div>
  );
};
