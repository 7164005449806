import { ChangeEventHandler, memo, useMemo } from 'react';
import styled from 'styled-components';

import { PositionLimitsItem } from './position-limits-item';

interface IItemProps {
  index: number;
  gameCode: string;
  item: IPositionLimitEditable;
  limit: number;
  sectorOptions: IOption[];

  setSectorCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setJobCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setLimitCb: TSetPositionLimitParamCb<number>;
  removeLimitCb: (index: number) => () => void;
}

interface IProps {
  gameCode: string;
  sectors: string[];
  keys: IPositionLimitEditable[];
  limits: number[];
  setSectorCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setJobCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setLimitCb: TSetPositionLimitParamCb<number>;
  removeLimitCb: (index: number) => () => void;
}

const stringToOption = (value: string) => ({ value, label: value });

const Styled = {
  Root: styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
  `,
};

const Item = memo((props: IItemProps) => {
  const {
    index,
    gameCode,
    item,
    limit,
    sectorOptions,
    setSectorCb,
    setJobCb,
    setLimitCb,
    removeLimitCb,
  } = props;

  const jobOptions = useMemo(
    () => item.availableJobs.map(stringToOption),
    [item]
  );

  const onSetSector = useMemo(() => setSectorCb(index), [index, setSectorCb]);
  const onSetJob = useMemo(() => setJobCb(index), [index, setJobCb]);
  const onSetLimit = useMemo(() => setLimitCb(index), [index, setLimitCb]);
  const onRemove = useMemo(() => removeLimitCb(index), [index, removeLimitCb]);

  return (
    <PositionLimitsItem
      gameCode={gameCode}
      key={item.sector + item.job}
      sector={item.sector}
      job={item.job}
      limit={limit}
      sectorOptions={sectorOptions}
      jobOptions={jobOptions}
      onChangeSector={onSetSector}
      onChangeJob={onSetJob}
      setLimit={onSetLimit}
      onRemove={onRemove}
    />
  );
});

export const PositionLimitsList = (props: IProps) => {
  const {
    gameCode,
    sectors,
    keys,
    limits,
    setSectorCb,
    setJobCb,
    setLimitCb,
    removeLimitCb,
  } = props;

  const sectorOptions = useMemo(() => sectors.map(stringToOption), [sectors]);

  const RenderItems = keys.map((key, index) => {
    return (
      <Item
        key={key.sector + key.job}
        index={index}
        gameCode={gameCode}
        sectorOptions={sectorOptions}
        item={key}
        limit={limits[index]}
        setSectorCb={setSectorCb}
        setJobCb={setJobCb}
        setLimitCb={setLimitCb}
        removeLimitCb={removeLimitCb}
      />
    );
  });

  return <Styled.Root>{RenderItems}</Styled.Root>;
};
