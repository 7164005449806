import { entriesObject } from '@avid/common';

import { TSectorEmployees, TSectorSettings } from 'typings/sectors-settings';

import { SectorLimitsRow } from './sector-limits-row';
import { useMemo } from 'react';
interface IProps {
  sectorEmployees: TSectorEmployees;
  sectorSettings: TSectorSettings;
  selectedLimits: string[];
  selectedSalaries: string[];
  onChangeLimit: (sector: string, limit: number) => void;
  onChangeSalary: (sector: string, salary: number) => void;
  onSelectLimit: (sector: string) => void;
  onSelectSalary: (sector: string) => void;
}

export const SectorLimitsTable = (props: IProps) => {
  const {
    sectorEmployees,
    sectorSettings,
    selectedLimits,
    selectedSalaries,
    onChangeLimit,
    onChangeSalary,
    onSelectLimit,
    onSelectSalary,
  } = props;

  const sectorsSorted = useMemo(
    () =>
      entriesObject(sectorEmployees)
        .sort((first, second) => second[1] - first[1])
        .map(([sector]) => sector),

    [sectorEmployees]
  );

  const RenderRows = sectorsSorted.map((sector) => {
    const settings = sectorSettings[sector];

    return (
      <SectorLimitsRow
        key={sector}
        sector={sector}
        employees={sectorEmployees[sector]}
        limit={settings.limit.value}
        isLimitChanged={settings.limit.isChanged}
        isLimitSelected={selectedLimits.includes(sector)}
        salary={settings.salary.value}
        isSalaryChanged={settings.salary.isChanged}
        isSalarySelected={selectedSalaries.includes(sector)}
        onChangeLimit={onChangeLimit}
        onChangeSalary={onChangeSalary}
        onSelectLimit={onSelectLimit}
        onSelectSalary={onSelectSalary}
      />
    );
  });

  return <div>{RenderRows}</div>;
};
