import { memo, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Span } from 'components/typography';
import { Input } from 'components/UI';
import { COLORS } from 'theme/colors';
import { typographyCss } from 'theme';

import { SectorLimitLimit } from './sector-limit-limit';
import { SectorLimitSalary } from './sector-limit-salary';

interface IProps {
  sector: string;
  employees: number;
  limit: number;
  isLimitChanged: boolean;
  isLimitSelected: boolean;
  salary: number;
  isSalaryChanged: boolean;
  isSalarySelected: boolean;
  onChangeLimit: (sector: string, limit: number) => void;
  onChangeSalary: (sector: string, salary: number) => void;
  onSelectLimit: (sector: string) => void;
  onSelectSalary: (sector: string) => void;
}

const Styles = {
  Root: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3px;

    &:nth-child(odd) > div {
      background-color: ${COLORS.blue_gray};
    }

    &:nth-child(even) > div {
      background-color: ${COLORS.whiteAntiFlash};
    }
  `,

  Cell: styled.div<{ $isContentStart?: boolean }>`
    display: grid;
    padding: 5px 16px;
  `,

  LeftCell: styled.div`
    display: grid;
    padding: 5px 24px 5px 16px;
    align-content: center;
    text-align: left;
  `,

  Input: styled(Input)<{ isChanged: boolean }>`
    border-radius: 10px;
    text-align: center;

    ${({ isChanged }) =>
      isChanged
        ? css`
            ${typographyCss.QuicksandBold16};
            color: ${COLORS.redSeconday};
          `
        : css`
            ${typographyCss.Regular};
            color: ${COLORS.black};
          `}
  `,
};

export const SectorLimitsRow = memo((props: IProps) => {
  const {
    sector,
    limit,
    isLimitChanged,
    isLimitSelected,
    isSalaryChanged,
    isSalarySelected,
    salary,
    employees,
    onChangeLimit,
    onChangeSalary,
    onSelectLimit,
    onSelectSalary,
  } = props;

  const handleChangeLimit = (newLimit: number) => {
    onChangeLimit(sector, newLimit);
  };

  const handleChangeSalary = useCallback(
    (newSalary: number) => {
      onChangeSalary(sector, newSalary);
    },
    [onChangeSalary, sector]
  );

  const handleSelectLimit = () => {
    onSelectLimit(sector);
  };

  const handleSelectSalary = useCallback(() => {
    onSelectSalary(sector);
  }, [onSelectSalary, sector]);

  return (
    <Styles.Root>
      <Styles.LeftCell>
        <Span typography="QuicksandBold18">{sector}</Span>
      </Styles.LeftCell>

      <Styles.Cell>
        <Span typography="QuicksandRegular18">{employees}</Span>
      </Styles.Cell>

      <Styles.Cell>
        <SectorLimitLimit
          limit={limit}
          isChanged={isLimitChanged}
          isSelected={isLimitSelected}
          onChange={handleChangeLimit}
          onSelect={handleSelectLimit}
        />
      </Styles.Cell>

      <Styles.Cell>
        <SectorLimitSalary
          salary={salary}
          isChanged={isSalaryChanged}
          isSelected={isSalarySelected}
          onChange={handleChangeSalary}
          onSelect={handleSelectSalary}
        />
      </Styles.Cell>
    </Styles.Root>
  );
});
