import { useState, useEffect } from 'react';

import { useRealTimePlayersCount } from 'services/hooks';
import { getEmployees } from 'services/realtime-firebase.api';

import { useActiveGameContext } from '../../../../active-game.context';

export const useEmploymentInfo = () => {
  const { game } = useActiveGameContext();

  const gameCode = game.config.gameCode;

  const [employeesCount, setEmployeesCount] = useState<number>();

  const { playersCount } = useRealTimePlayersCount(gameCode);

  const nonEmployeesCount = playersCount
    ? playersCount - (employeesCount || 0)
    : 0;

  useEffect(() => {
    const request = async () => {
      const employeesCountFetched = await getEmployees(gameCode);

      setEmployeesCount(employeesCountFetched);
    };

    request();
  }, [gameCode]);

  return { playersCount, employeesCount, nonEmployeesCount };
};
