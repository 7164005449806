import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import {
  IGameConfig,
  IGameMods,
  ITrainerLimits,
  TGameVersion,
} from '@avid/common';

import { fetch } from 'services/firebase.api';
import { moveToRoute } from 'services/history';
import { useUpdateState } from 'services/hooks';
import {
  getEducationSettings,
  getGameIsSelfPaced,
  getModDefaultSettings,
  isValidLimit,
} from 'services/utils';
import { ROUTES } from 'constants/routes';
import { TDBSectors } from 'typings/games';
import { callableFunctions } from 'services/api';
import { MAX_GAME_PLAYERS } from 'constants/validation';
import { ISelectData } from 'screens/statistics/statistics.types';
import { versionsAPI } from 'services/api/versions.api';
import { valuesOfObject } from 'services/object';
import { CLIENT_ID } from 'constants/settings';

import {
  IGameStateConfig,
  INewGameEducation,
  TNewGameValidateFields,
} from './new-game.typing';
import {
  durationToSeconds,
  getValidationState,
  validateDuration,
  getValidValueCb,
  transformJobLimits,
} from './new-game.utils';
import {
  getJobLimits,
  NEW_GAME_CONSTANTS,
  RESERVED_PREFIXES,
} from './new-game.constants';

const INITIAL_STATE: IGameStateConfig = {
  gameTitle: '',
  gameCode: '',
  isSelfPaced: false,
  description: '',
  roundsNumber: '10',
  energy: '20',
  roundDuration: `00:00:10`,
  account: '3000',
  date: null,
  minimalMoney: '-20000',
  startEducationOrder: 0,
};

interface IGameCodeEditorState {
  isEdit: boolean;
  isLoading: boolean;
  gameCode?: string;
}

const INIT_GAME_CODE_EDITOR_STATE: IGameCodeEditorState = {
  isEdit: false,
  isLoading: false,
};

const REGEXP_ANY_DIGIT = /^-\$\d+$/;
const REGEXP_GAME_CODE = /^\d{6}$/;
const REGEXP_NUMBER_OR_EMPTY = /^(\d+)?$/;

const checkWrong = (field: string, value: string) => {
  const validationMap: Record<
    keyof TNewGameValidateFields,
    (fieldValue: string) => boolean
  > = {
    gameCode: (fieldValue: string) => !fieldValue.match(REGEXP_GAME_CODE),
    gameTitle: (fieldValue: string) => fieldValue.length > 3,
    description: (fieldValue: string) => fieldValue.length > 3,
    roundsNumber: (fieldValue: string) => +fieldValue > 1,
    energy: (fieldValue: string) => +fieldValue > 1,
    roundDuration: (fieldValue: string) =>
      !!+value.split(':').join('') && validateDuration(fieldValue),
    account: (fieldValue: string) => +fieldValue.split('$')[1] > 1,
    minimalMoney: (fieldValue: string) =>
      REGEXP_ANY_DIGIT.test(fieldValue.trim()) &&
      +fieldValue.substring(2) > 1000,
    version: (selectorValue: string) => !!selectorValue,
    endAt: (fieldValue: string) => {
      const time = moment(fieldValue, NEW_GAME_CONSTANTS.END_AT_FORMAT, true);
      const isValid = time.isValid() && time.isAfter();

      return isValid;
    },
  };

  const isWrong = !validationMap[field as keyof TNewGameValidateFields](value);

  return isWrong;
};

const getRandomGameCode = () => {
  const code = String(Math.floor(Math.random() * 900000) + 100000);

  if (RESERVED_PREFIXES.includes(code.substring(0, 2))) {
    return getRandomGameCode();
  }

  return code;
};

export const useGameState = (gameConfig?: IGameStateConfig) => {
  const { state, updateState } = useUpdateState(gameConfig || INITIAL_STATE);
  const { account, roundDuration, minimalMoney } = state;
  const { state: fieldsWrong, updateState: setIsWrong } = useUpdateState(
    getValidationState(Boolean(gameConfig))
  );
  const { state: education, updateState: updateEducation } =
    useUpdateState<INewGameEducation>({
      options: [{ label: 'Select game version', value: 'select' }],
      selected: null,
    });

  const { state: gameCodeEditorState, updateState: updateGameCodeEditorState } =
    useUpdateState(INIT_GAME_CODE_EDITOR_STATE);

  const [jobLimits, setJobLimits] = useState<TDBSectors>();
  const [showError, setShowError] = useState(false);
  const [exceedLimits, setExceedLimits] = useState<(keyof ITrainerLimits)[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const generateGameCode = useCallback(async () => {
    const code = getRandomGameCode();

    const isCodeData = await fetch('games', code);

    if (isCodeData) {
      generateGameCode();
    }

    return updateState({ gameCode: code });
  }, [updateState]);

  const onSwitchGameCodeEditor = async () => {
    if (gameCodeEditorState.isLoading) {
      return;
    }

    if (gameCodeEditorState.isEdit) {
      if (!gameCodeEditorState.gameCode?.match(REGEXP_GAME_CODE)) {
        updateGameCodeEditorState({ isLoading: false, isEdit: false });
        return;
      }

      updateGameCodeEditorState({ isLoading: true });

      const code = gameCodeEditorState.gameCode;

      const isCodeData = await fetch('games', code);

      if (isCodeData) {
        setIsWrong({ gameCode: true });
        updateGameCodeEditorState({ isLoading: false });
        return;
      }

      updateState({ gameCode: code });
      updateGameCodeEditorState({ isEdit: false, isLoading: false });
      return;
    }

    updateGameCodeEditorState({ gameCode: state.gameCode, isEdit: true });
  };

  const onEditGameCode: ChangeEventHandler<HTMLInputElement> = (ev) => {
    if (gameCodeEditorState.isLoading || !gameCodeEditorState.isEdit) {
      return;
    }

    const value = ev.target.value;

    if (!value.match(REGEXP_NUMBER_OR_EMPTY) || value.length > 6) {
      return;
    }

    updateGameCodeEditorState({ gameCode: ev.target.value });
    setIsWrong({ gameCode: false });
  };

  const validateData = (field: string, value: string) =>
    setIsWrong({
      [field]: checkWrong(field, value),
    });

  const isFieldsWrong = () => Object.values(fieldsWrong).find((value) => value);

  const setValue = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const isNumField = name === 'roundsNumber' || name === 'energy';

    event.persist();

    validateData(name, value);

    if (isNumField && value.length > 6) {
      return;
    }

    updateState({ [name]: isNumField ? String(+value) : value });

    if (
      (name === 'roundsNumber' || name === 'roundDuration') &&
      exceedLimits.includes('gameDuration')
    ) {
      setExceedLimits((prev) =>
        prev.filter((limit) => limit !== 'gameDuration')
      );
    }
  };

  const setJobLimit = useCallback((sector: string, limit: string | number) => {
    if (!isValidLimit(limit)) {
      return;
    }

    setJobLimits((s) => {
      return {
        ...s,
        [sector]: {
          limit: +limit > MAX_GAME_PLAYERS ? MAX_GAME_PLAYERS : +limit,
          players: {},
        },
      };
    });
  }, []);

  const onSave = async () => {
    if (isLoading) {
      return;
    }

    if (isFieldsWrong()) {
      return setShowError(true);
    }

    setIsLoading(true);

    const config: Partial<IGameConfig> = {
      ...state,

      date: moment().unix(),
      account: account.replace('$', ''),
      roundDuration: durationToSeconds(roundDuration),
      minimalMoney: minimalMoney.trim().replace('$', ''),
    };

    if (config.isSelfPaced && config.endAt) {
      config.endAt = moment(
        state.endAt,
        NEW_GAME_CONSTANTS.END_AT_FORMAT
      ).toISOString();
    }

    try {
      await callableFunctions.createGame({
        clientId: CLIENT_ID,
        config,
        sectorLimits: transformJobLimits(jobLimits),
      });

      setIsLoading(false);

      moveToRoute(ROUTES.START);
    } catch (error) {
      const cast = error as any;

      if (cast.message.startsWith('limits')) {
        setExceedLimits(cast.details);
      }

      console.error(error);
      setIsLoading(false);
    }
  };

  const onChangeFlow = (option: IOption) => {
    const isSelfPaced = getGameIsSelfPaced(option);

    if (isSelfPaced) {
      validateData('endAt', state.endAt || '');
    } else {
      setIsWrong({ endAt: false });
    }

    updateState({ isSelfPaced });
  };

  const onChangeEndAt = (endAt: string) => {
    validateData('endAt', endAt);
    updateState({ endAt });
  };

  const onChangeVersion = async (option: any) => {
    const version = (option as ISelectData).value as TGameVersion;
    const versionLoadedEducation = education?.loadedLevels?.[version];

    updateState({ version });
    validateData('version', version);

    if (!versionLoadedEducation) {
      const fetchedEducation = (await versionsAPI.getEducationLevels(version))
        ?.levels;

      if (fetchedEducation) {
        updateEducation((prev) => ({
          ...prev,
          loadedLevels: { ...prev.loadedLevels, [version]: fetchedEducation },
          options: getEducationSettings(fetchedEducation),
          selected: null,
        }));
      }
      return;
    }

    updateEducation({
      options: getEducationSettings(versionLoadedEducation),
      selected: null,
    });
  };

  const onChangeEducation = (option: any) => {
    const educationName = (option as ISelectData).value;

    if (!state.version) {
      return;
    }

    updateEducation((prev) => ({ ...prev, selected: option as ISelectData }));
    updateState({
      startEducationOrder:
        education?.loadedLevels?.[state.version]?.[educationName]?.order || 0,
    });
  };

  const onSwitchMod = (
    modName: keyof IGameMods,
    opts?: { enable?: (keyof IGameMods)[]; disable?: (keyof IGameMods)[] }
  ) => {
    if (!Boolean(state.mods?.[modName])) {
      updateState((prev) => {
        const mods = { ...(prev.mods || {}) };

        const enableMods: any = {};

        if (opts?.enable) {
          for (const enableMod of opts?.enable) {
            enableMods[enableMod] = getModDefaultSettings(
              enableMod,
              durationToSeconds(prev.roundDuration)
            );
          }
        }

        return {
          ...prev,
          mods: {
            ...mods,
            [modName]: getModDefaultSettings(
              modName,
              durationToSeconds(prev.roundDuration)
            ),
            ...enableMods,
          },
        };
      });

      return;
    }

    updateState((prev) => {
      const mods = { ...(prev.mods || {}) };

      delete mods?.[modName];

      if (opts?.disable) {
        for (const disabledMod of opts?.disable) {
          delete mods?.[disabledMod];
        }
      }

      if (valuesOfObject(mods).length === 0) {
        return { ...prev, mods: undefined };
      }

      return { ...prev, mods: { ...mods } };
    });
  };

  const changeGameModConfigCb =
    (modName: keyof IGameMods) => (prop: string, value: string | number) => {
      const validValue = getValidValueCb(
        +state.roundsNumber,
        +roundDuration
      )({ modName, prop, value });

      updateState((prev) => ({
        ...prev,
        mods: {
          ...prev.mods,
          [modName]: { ...prev.mods?.[modName], [prop]: validValue },
        },
      }));
    };

  useEffect(() => {
    const version = state.version;

    if (!version) {
      return;
    }

    getJobLimits(version, setJobLimits);
  }, [state.version]);

  useEffect(() => {
    (gameConfig && gameConfig.gameCode) || generateGameCode();
  }, [gameConfig, generateGameCode]);

  return {
    config: state,
    selectedEducation: education.selected,
    educationOptions: education.options,
    gameCodeEditorState,

    jobLimits,
    fieldsWrong,
    showError,
    isAdminLimited: exceedLimits.length > 0,
    isDurationExceed: exceedLimits.includes('gameDuration'),
    isLoading,

    onChangeEducation,
    onSwitchGameCodeEditor,
    onEditGameCode,
    setValue,
    onChangeEndAt,
    setJobLimit,
    onSave,
    onChangeFlow,
    onChangeVersion,
    onSwitchMod,
    changeGameModConfigCb,
  };
};
