import { Paragraph } from 'components/typography';
import { Grid } from 'components/UI';

import { SectorLimitsEditor } from './sector-limits-editor';

interface SectorLimitsProps {
  hideNotification: () => void;
  showNotification: () => void;
}

export const SectorLimits = (props: SectorLimitsProps) => {
  const { hideNotification, showNotification } = props;

  return (
    <Grid rowGap="12px">
      <Paragraph typography="RalewaySemiBold20" textAlign="left">
        Sector Limits
      </Paragraph>

      <SectorLimitsEditor
        hideNotification={hideNotification}
        showNotification={showNotification}
      />
    </Grid>
  );
};
