import { Loader } from 'components/loader';
import { Grid } from 'components/UI';

import { SectorLimitsTable } from '../sector-limits-table';
import { SectorLimitsHead } from '../sector-limits-head';
import { SectorLimitsButton } from '../sector-limits-button';
import { SectorLimitsApply } from '../sector-limits-apply';

import { useSectorLimitsEditor } from './sector-limits-editor.state';

interface IProps {
  hideNotification: () => void;
  showNotification: () => void;
}

export const SectorLimitsEditor = (props: IProps) => {
  const { hideNotification, showNotification } = props;

  const {
    sectorSettings,
    isLoading,
    isSaving,
    sectorEmployees,
    limit,
    salary,
    isAllLimitsSelected,
    isAllSalariesSelected,
    isLimitApplyDisabled,
    isSalaryApplyDisabled,
    isCantSave,

    onChangeLimit,
    onChangeSalary,
    onSelectLimit,
    onSelectSalary,
    onToggleAll,
    onApplyParam,
    onSave,
  } = useSectorLimitsEditor({ hideNotification, showNotification });

  if (isLoading || !sectorSettings) {
    return <Loader />;
  }

  return (
    <Grid rowGap="16px">
      <div>
        <SectorLimitsHead
          isAllLimitsSelected={isAllLimitsSelected}
          isAllSalariesSelected={isAllSalariesSelected}
          onToggleAllItems={onToggleAll}
        />

        <SectorLimitsTable
          sectorEmployees={sectorEmployees}
          sectorSettings={sectorSettings}
          selectedLimits={limit.selected}
          selectedSalaries={salary.selected}
          onChangeLimit={onChangeLimit}
          onChangeSalary={onChangeSalary}
          onSelectLimit={onSelectLimit}
          onSelectSalary={onSelectSalary}
        />

        <SectorLimitsApply
          isLimitDisabled={isLimitApplyDisabled}
          isSalaryDisabled={isSalaryApplyDisabled}
          onApplyParam={onApplyParam}
        />
      </div>

      <SectorLimitsButton
        isLoading={isSaving}
        isCanSave={!isCantSave}
        onSave={onSave}
      />
    </Grid>
  );
};
