import { useCallback, useEffect, useRef, useState } from 'react';

export const useWorkLimitsModal = () => {
  const [isShowNotification, setIsShowNotification] = useState(false);

  const scrollableRef = useRef<HTMLDivElement>(null);
  const notificationTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onNonDraggableMouseDown = useCallback((ev: React.MouseEvent) => {
    ev.stopPropagation();
  }, []);

  const scrollToBottom = useCallback(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: scrollableRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  const hideNotification = useCallback(() => {
    setIsShowNotification(false);

    if (notificationTimeoutRef.current) {
      clearTimeout(notificationTimeoutRef.current);
    }
  }, []);

  const showNotification = useCallback(() => {
    setIsShowNotification(true);

    notificationTimeoutRef.current = setTimeout(() => {
      setIsShowNotification(false);
    }, 4000);
  }, []);

  useEffect(() => {
    return () => {
      if (notificationTimeoutRef.current) {
        clearTimeout(notificationTimeoutRef.current);
      }
    };
  }, []);

  return {
    isShowNotification,
    scrollableRef,
    onNonDraggableMouseDown,
    scrollToBottom,
    hideNotification,
    showNotification,
  };
};
