import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { TGameVersion } from '@avid/common';

import { keyofObject } from 'services/object';

import { PositionLimitsList } from '../position-limits-list';
import { Button } from '../button';

import {
  usePositionLimitsEditor,
  usePositionsLoader,
} from './positions-limits-editor.state';
import { Grid } from 'components/UI';

interface IProps {
  gameCode: string;
  version: TGameVersion;
  scrollBottom: () => void;
  hideNotification: () => void;
  showNotification: () => void;
}

const Styles = {
  Buttons: styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 16px;
    justify-content: space-between;
  `,
};

export const PositionLimitsEditor = memo((props: IProps) => {
  const {
    gameCode,
    version,
    scrollBottom,
    hideNotification,
    showNotification,
  } = props;

  const {
    keys,
    limits,
    isSaving,
    onAddLimit,
    setSectorCb,
    setJobCb,
    setLimitCb,
    removeLimitCb,
    onSave,
  } = usePositionLimitsEditor({
    gameCode,
    scrollBottom,
    hideNotification,
    showNotification,
  });

  const { sectorJobsMap } = usePositionsLoader({
    version,
  });

  const { availableKeys, availableSectors } = useMemo(() => {
    if (!sectorJobsMap) {
      return { availableKeys: [], availableSectors: [] };
    }

    const sectors = keyofObject(sectorJobsMap);

    return {
      availableSectors: sectors,

      availableKeys: keys.map((key, index) => ({
        ...key,
        availableJobs: (sectorJobsMap[key.sector] || []).filter(
          (job) =>
            keys.findIndex(
              (fKey, fIndex) =>
                fKey.sector === key.sector &&
                fKey.job === job &&
                fIndex !== index
            ) === -1
        ),
      })),
    };
  }, [keys, sectorJobsMap]);

  return (
    <Grid rowGap="20px">
      <PositionLimitsList
        gameCode={gameCode}
        sectors={availableSectors}
        keys={availableKeys}
        limits={limits}
        setSectorCb={setSectorCb}
        setJobCb={setJobCb}
        setLimitCb={setLimitCb}
        removeLimitCb={removeLimitCb}
      />

      <Styles.Buttons>
        <Button text="Add New Limit" isLightTheme onClick={onAddLimit} />
        <Button
          text={isSaving ? 'Saving...' : 'Save Limits'}
          isDisabled={isSaving}
          onClick={onSave}
        />
      </Styles.Buttons>
    </Grid>
  );
});
