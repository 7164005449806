import { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { useUpdateState } from 'services/hooks';
import { getGameIdentity } from 'services/utils';
import { callGameAdminFunction, GAME_ADMIN_ACTIONS } from 'services/api';
import { IActiveGameMatchParams } from 'typings/games';
import { IReflectionAnswerRow } from 'typings/reflection-questions.typing';

interface IState {
  isLoading: boolean;
  answers?: IReflectionAnswerRow[];
}

const INIT_STATE: IState = {
  isLoading: false,
};

export const useReflectionAnswers = () => {
  const { gameCode } = useParams<IActiveGameMatchParams>();

  const { state, updateState } = useUpdateState(INIT_STATE);

  const linkRef = useRef(CSVLink) as any;

  const onClick = useCallback(async () => {
    if (state.isLoading) {
      return;
    }

    try {
      const { answers } = await callGameAdminFunction(
        getGameIdentity(gameCode),
        GAME_ADMIN_ACTIONS.GET_REFLECTION_ANSWERS,
        null
      );

      updateState({ isLoading: false, answers });

      setTimeout(() => {
        linkRef.current.link.click();
      }, 0);
    } catch (error) {
      console.error(error);
      updateState({ isLoading: false });
    }
  }, [gameCode, state.isLoading, updateState]);

  return { ...state, linkRef, onClick };
};
